<template>
  <section class="doc-audit-result">
    <v-data-table
      id="audit-result-table"
      :headers="tableHeaders"
      :items="evaluationResult"
      disable-pagination
      disable-sort
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr :class="item.className">
          <td style="font-weight-bold">{{ item.name }}.</td>
          <td>{{ item.percent }}%</td>
          <td>{{ item.amount }}</td>
        </tr>
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  name: "DocBatchAuditResult",

  props: {
    evaluationResult: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const tableHeaders = [
      {
        text: "Audits statistics",
        sortable: false,
        value: "name",
      },
      {
        text: "Value, %",
        sortable: false,
        value: "percent",
      },
      {
        text: "Value, n",
        sortable: false,
        value: "amount",
      },
    ];

    return {
      tableHeaders,
    };
  },
};
</script>

<style>
  .green-evaluation {
    background-color: #dcffd6;
  }

  .amber-evaluation {
    background-color: #ffff9e;
  }

  .red-evaluation {
    background-color: #ffa598;
  }
</style>
