<template>
  <v-main>
    <reporting-tabs />
  </v-main>
</template>

<script>
import ReportingTabs from "@/components/reporting/ReportingTabs.vue";
export default {
  name: "ReportingPage",

  components: {
    ReportingTabs,
  },
};
</script>